import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HTTP } from '@ionic-native/http/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AngularODataModule, ODataConfiguration, ODataServiceFactory } from 'angular-odata-es5';
import { IonicImageLoaderModule } from 'ionic-image-loader-v5';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';

export class AppODataConfig extends ODataConfiguration {
	baseUrl = "https://rgsaudioapp.azurewebsites.net/odata/";
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule,
    HttpClientModule,
    AngularODataModule.forRoot(), IonicModule.forRoot(), AppRoutingModule, IonicImageLoaderModule, SharedModule],
  providers: [
    StatusBar,
    SplashScreen,
    HTTP,
    WebView,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    ODataServiceFactory,
    { provide: ODataConfiguration, useClass: AppODataConfig }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
